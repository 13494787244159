import React from "react";
import { BiLogoTelegram } from "react-icons/bi";

const App: React.FC = () => {
  return (
    <div className="h-screen flex items-center justify-center bg-white flex-col space-y-8 relative text-center">
      <img
        src="selldottoplogo.png"
        alt=""
        className="h-16 w-16 absolute left-3 top-3"
      />
      <h1 className="md:text-6xl text-5xl text-[#FB5A17] tracking-[1rem]">
        SELL.TOP
      </h1>
      <p className="text-black font-semibold">
        Private OTC order book on Solana
      </p>
      <a
        className="flex items-center justify-center"
        href="https://t.me/+FT70MVgurWAyNjMx"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BiLogoTelegram className="text-4xl text-black hover:text-[#FB5A17] ease-in-out duration-300" />
      </a>
    </div>
  );
};

export default App;
